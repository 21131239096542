@import "styles/mixins";

// Shared styles
.add_to_bag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 5px;
  background-color: #e0d8ea;
  border: 1px solid #e0d8ea;
  color: var(--clr-primary-300);
  font-weight: 600;
  line-height: 1.3rem;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--clr-primary-300);
    border-color: var(--clr-primary-300);
    color: var(--clr-neutral-100);
  }

  @include mobile() {
    margin: 0;
    font-size: 0.875rem;
  }
}

.weight_select {
  font-size: 0.875rem;
  border: 1px solid transparent;
  background: var(--clr-neutral-200);
  border-radius: 50px;
  padding: 2px 4px;
  margin-left: 10px;

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible {
    border: 1px solid var(--clr-primary-300);
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.weight {
  font-size: 0.875rem;
  svg {
    vertical-align: -.125em;
    display:inline-block;
    font-size: 0.8rem;
    opacity: 0.4;
  }
}

.price {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: var(--clr-primary-300);

  @include mobile() {
    font-size: 0.875rem;
  }
}

.originalPrice {
  color: var(--clr-primary-300);
  text-decoration: line-through;
  text-decoration-color: var(--clr-neutral-850);
  font-weight: 500;
  font-size: 0.75rem;
}

.discountedPrice {
  color: var(--clr-accent-300);
  font-weight: 600;
  font-size: 1rem;

  @include mobile() {
    font-size: 0.875rem;
  }
}

.modify_cart {
  background-color: var(--clr-neutral-175);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 5px;
  border: 1px solid transparent;
  color: var(--clr-accent-300);
  font-weight: 600;
  line-height: 1.3rem;

  button {
    display:flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: var(--clr-primary-300);
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-width: 40px;

    &:hover {
      background: var(--clr-primary-300);
      color: var(--clr-neutral-100);
    }
  }

  &:hover {
    cursor: default;
  }

  @include mobile() {
    font-size: 0.75rem;
  }

  span {
    width: 100%;
    text-align: center;
    user-select: none;
  }

  .trash {
    color: var(--clr-danger);
    background: none;
    border-color: var(--clr-danger);

    &:hover {
      color: var(--clr-neutral-100);
      background: var(--clr-danger);
    }
  }

  .deactive {
    pointer-events: none;
    border-color: var(--clr-neutral-300);
    color: var(--clr-neutral-300);
  }
}

// Card styles
.card {
  .priceWeight {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
    width: 100%;
    font-weight: 500;
    text-align: right;
    padding-inline: 1rem;

    @include mobile() {
      padding-inline: 0.5rem;
    }
  }

  .addToCart {
    display: flex;
    // flex-direction: column;
    flex-wrap:wrap;
    justify-content: flex-end;
    gap: 0.5rem;
  }
  .buttonContainer {
    flex: 1;
    width: 100%;
  }
  .add_to_bag {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
}

// ProductPage styles
.productPage {
  .priceWeight {
    display: flex;
    gap: 0.5rem;
  }

  .addToCart {
    display:flex;
    flex-direction: column;
    gap:0.5rem;
    @include mobile() {
      flex-direction: row;
      align-items: center;
    }
  }
  .modify_cart {
    border-radius:50px;
  }

  .buttonContainer {
    flex: 1;
    width: 100%;
  }

  .add_to_bag {
    border-radius: 50px;
  }
}
